
import React from "react"
import {graphql} from 'gatsby'
import {
  Avatar,
  Card, CardContent, CardHeader, CardMedia,
  Grid,
  Link,
  List, ListItem, ListItemAvatar, ListItemText,
  Accordion, AccordionSummary, AccordionDetails,
  Typography
} from "@material-ui/core"
import ExpandMore from '@material-ui/icons/ExpandMore';
import Layout from '../../../layout'
import Seo from "../../../components/Seo"

const styles = {
  media: {
    height: 140,
  },
  news: {
    marginTop: '3rem',
  },
}

export default function Dashboard ({
  data,
  location,
}) {
  return (
    <Layout>
      <Seo
        lang={"en"}
        title="User dashboard"
        description={``}
        keywords={[].join(', ')}
        slug={location.pathname}
        />
      <Typography variant="h1" gutterBottom={true}>Welcome!</Typography>
      <Grid container justify="center" spacing={6}>
        {data.sections.nodes.map(node => (
          <Grid item xs={6} key={node.key}>
            <Card>
              <CardHeader
                title={node.title}
                subheader={node.subtitle}
                />
              <CardMedia
                css={styles.media}
                image={require(`./assets/${node.key}.png`).default}
                title={node.title}
                />
              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  {node.description}
                </Typography>
              </CardContent>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Documents</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List component="nav" disablePadding>
                    {node.docs.map(doc => (
                      <ListItem
                        key={doc.slug}
                        component={Link}
                        href={doc.slug}
                        >
                        <ListItemText primary={doc.frontmatter.title} />
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Typography css={styles.news} variant="h2">Latest news</Typography>
      <List>
        {data.articles.nodes.map(node => (
          <ListItem key={node.slug} alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
            </ListItemAvatar>
            <ListItemText
              primary={node.frontmatter.title + " — " + node.date}
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary"
                    >
                  </Typography>
                  {/* not happy about it, it create an <p><div><p>ugly output</p></div></p> */}
                  <div style={{display: 'inline'}} dangerouslySetInnerHTML={{ __html: node.parent.html}} />
                </React.Fragment>
              }
              />
          </ListItem>
        ))}
      </List>
    </Layout>
  )
}

export const query = graphql`
query {
  articles: allCloudArticle(
    filter: { lang: { eq: "en" } }
    sort: { order: DESC, fields: [date] }
  ) {
    nodes {
      date(formatString: "ll", locale: "en")
      frontmatter {
        title
        date
      }
      lang
      parent {
        ... on MarkdownRemark {
          html
          excerpt(pruneLength: 500)
        }
      }
      slug
    }
  }
  sections: allCloudSection {
    nodes {
      docs {
        frontmatter {
          title
        }
        slug
      }
      key
      title
      subtitle
      description
    }
  }
}
`
